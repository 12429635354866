import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import styles from './AnnualReports.module.scss'
import { getIconByName } from '@algbra/ui/icons'
import Icon from '@algbra/ui/components/Icon/Icon'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import { resolveAsAttachment, resolveAsImage } from '@algbra/ui/resolvers'
import { GatsbyImage } from 'gatsby-plugin-image'
import Button from '@algbra/ui/components/Button/Button'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import { useEffect, useRef, useState } from 'react'
import Dialog from '@algbra/ui/components/Dialog/Dialog'
import Caption from '@algbra/ui/components/Caption/Caption'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import ReCAPTCHA from 'react-google-recaptcha'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { navigate } from 'gatsby'
import config from '../../config'
import Input from '@algbra/ui/components/Input/Input'
import Lottie from 'react-lottie'
import * as animationData from './alerts-success.json'
import Select from '@algbra/ui/components/Select/Select'
import { uniq, map, max, head } from 'lodash'

const lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

type Report = {
  name: string
  content: string
  year: number
  pdf: GatsbyResolvedAsset
}

export type ZoneAnnualReportsProps = {
  __typename: 'StrapiGQL_ComponentZonesAnnualReports'
  id: string
  heroTitle: string
  heroSubtitle: string
  buttonTitle: string
  reportsTableTitle: string
  image: GatsbyResolvedAsset
  annualReport: GatsbyResolvedAsset
  reports: Report[]
}

export const schema = yup.object().shape({
  name: yup.string().required().label('Name'),
  email: yup.string().email().required().label('E-mail'),
})

export default function ZoneAnnualReports(props: ZoneAnnualReportsProps) {
  const [showModal, setShowModal] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [dropdownData, setDropdownData] = useState([])
  const [selectedYear, setSelectedYear] = useState<string>()
  const [filteredReports, setFilteredReports] = useState([])

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(schema),
    criteriaMode: 'firstError',
    defaultValues: {
      name: '',
      email: '',
    },
  })

  useEffect(() => {
    const reportYearsRaw = uniq(map(props.reports, 'year')).sort(
      (a, b) => b - a
    )

    const reportYears = reportYearsRaw.map(year => {
      return { value: year, label: year }
    })

    setDropdownData(reportYears)
    setSelectedYear(max(reportYearsRaw).toString())
  }, [props.reports])

  useEffect(() => {
    setFilteredReports(
      props.reports.filter(report => report.year.toString() === selectedYear)
    )
  }, [selectedYear, props.reports])

  const onFormValid = async values => {
    const token = await recaptchaRef.current.executeAsync()
    setLoading(true)
    const formData = getValues()

    axios
      .post('/.netlify/functions/subscribe', {
        ...formData,
        token,
      })
      .then(() => {
        setLoading(false)
        setSubmitted(true)

        if (recaptchaRef?.current) {
          recaptchaRef.current.reset()
        }

        const a = document.createElement('a')
        a.href = resolveAsAttachment(props.annualReport)
        a.download = 'Algbra_Annual_Report'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)

        navigate('#successful-registration')
      })
      .catch(e => {
        window.dataLayer = window.dataLayer || []

        setLoading(false)
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset()
        }
        navigate('#failed-registration')
        window.alert(
          'There was an error trying to send your request. Please try again later.'
        )
      })
  }

  return (
    <Whitespace type="md">
      <div className={styles.hero}>
        <div className={styles.textContainer}>
          <Heading className={styles.heroTitle} type="lg">
            {props.heroTitle}
          </Heading>

          <FreeText innerHTML={props.heroSubtitle} style={{ marginBottom: 32 }}>
            {props.heroSubtitle}
          </FreeText>

          <Button
            size="normal"
            onClick={() => {
              setShowModal(true)
            }}
          >
            {props.buttonTitle}
          </Button>
        </div>

        <div className={styles.imageContainer}>
          <GatsbyImage
            image={resolveAsImage(props.image)}
            alt={props.heroTitle}
            loading="lazy"
            className={styles.image}
          />
        </div>
      </div>

      <Container>
        <Heading type="lg" className={styles.tableTitle}>
          {props.reportsTableTitle}
        </Heading>

        <Select
          name="test"
          items={dropdownData}
          controlClassName={styles.select}
          onChange={e => setSelectedYear(e.target.value)}
          className={styles.selectContainer}
        ></Select>

        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.tableHeader}>Documents</th>
              <th className={styles.tableHeader}>Year</th>
              <th className={styles.tableHeader}>Content</th>
              <th className={styles.tableHeader}>Download</th>
            </tr>
          </thead>

          <tbody>
            {filteredReports.map(report => {
              return (
                <tr key={report.name}>
                  <td>
                    <div className={styles.nameColumn}>{report.name}</div>
                    <div
                      className={styles.contentMobile}
                      dangerouslySetInnerHTML={{ __html: report.content }}
                    ></div>
                  </td>
                  <td>{report.year}</td>
                  <td dangerouslySetInnerHTML={{ __html: report.content }}></td>

                  <td className={styles.downloadIconCell}>
                    <Permalink
                      to={resolveAsAttachment(report.pdf)}
                      isExternal={true}
                      isBlank={true}
                    >
                      <div className={styles.iconWrapper}>
                        <Icon
                          icon={getIconByName('arrow-right')}
                          className={styles.icon}
                        />
                      </div>
                    </Permalink>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Container>

      <Dialog
        className={styles.dialog}
        isOpen={showModal}
        onClose={() => {
          setShowModal(false)
          return false
        }}
      >
        <Heading level={1} type="lg" className={styles.title}>
          Download our Annual Report
        </Heading>

        {!isSubmitted && (
          <form className={styles.form} id="annual-reports-page">
            <ReCAPTCHA
              size="invisible"
              ref={recaptchaRef}
              sitekey={config.reCaptchaKey}
            />
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  id="ID-004-edit-name-field"
                  placeholder="Name"
                  controlClassName={styles.input}
                  className={styles.inputContainer}
                  error={error}
                />
              )}
            />

            <Controller
              control={control}
              name="email"
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  id="ID-004-edit-email-field"
                  placeholder="E-mail"
                  type="email"
                  controlClassName={styles.input}
                  className={styles.inputContainer}
                  error={error}
                />
              )}
            />

            <FreeText className={styles.helpText} type="sm">
              By entering your email, you agree to our{' '}
              <Permalink
                to="https://www.algbra.com/privacy-policy/"
                isBlank={true}
                isExternal={true}
              >
                Privacy Policy
              </Permalink>
            </FreeText>

            <Button
              onClick={handleSubmit(onFormValid)}
              id="ID-005-submit-button"
              block={true}
              theme="primary"
              type="submit"
              loading={isLoading}
            >
              Download
            </Button>
          </form>
        )}

        {isSubmitted && (
          <div
            className={styles.submitted}
            id="newsletter-annual-reports-page--success"
          >
            <Lottie options={lottieOptions} height={60} width={60} />
          </div>
        )}
      </Dialog>
    </Whitespace>
  )
}
