import { graphql } from 'gatsby'
import PageLayout from '../components/PageLayout/PageLayout'
import { getMetaTags } from '@algbra/utils/metaTags'
import MainZoneRenderer from '../zones/MainContentZone'
import SubpageHeader from '../components/SubpageHeader/SubpageHeader'

export default function LayoutGeneral({ pageContext, data, location }) {
  const zones = data.strapiGQL.corporatePage.contentZones

  return (
    <PageLayout>
      <SubpageHeader
        title={pageContext.title}
        summary={pageContext.subtitle}
        metaTags={getMetaTags(pageContext)}
      />

      {zones && <MainZoneRenderer zones={zones} location={location} />}
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      corporatePage(id: $id) {
        ...StrapiGQL_CorporatePagesWithContentZones
      }
    }
  }
`
