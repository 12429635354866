import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import TeamMember, {
  TeamMemberProps,
} from '@algbra/ui/components/TeamMember/TeamMember'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { graphql, StaticQuery } from 'gatsby'
import { mapIndexed } from 'rambdax'

const TEAMS_QUERY = graphql`
  query TeamQueryTemp {
    strapiGQL {
      teamMemberships(publicationState: LIVE) {
        values_and_ethics_committees(sort: "ethicsCommitteeOrder") {
          ...StrapiGQL_TeamFragment
        }
      }
    }
  }
`
export type ZoneEthicsCommitteeProps = {
  __typename: 'StrapiGQL_ComponentZonesEthicsCommittee'
}
export default function ZoneEthicsCommittee(props: ZoneEthicsCommitteeProps) {
  return (
    <StaticQuery
      query={TEAMS_QUERY}
      render={({ strapiGQL: { teamMemberships } }) => {
        return (
          <Whitespace type="md">
            <Container>
              <Row>
                {mapIndexed(
                  (node: TeamMemberProps, index) => (
                    <Column size={6} sm={4} lg={4} key={`team-column-${index}`}>
                      <div style={{ maxWidth: 270 }}>
                        <TeamMember hasDialog={true} {...node} />
                      </div>
                    </Column>
                  ),
                  teamMemberships[0].values_and_ethics_committees || []
                )}
              </Row>
            </Container>
          </Whitespace>
        )
      }}
    />
  )
}
