import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import TeamMember, {
  TeamMemberProps,
} from '@algbra/ui/components/TeamMember/TeamMember'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { graphql, StaticQuery } from 'gatsby'
import { mapIndexed } from 'rambdax'
import { get, head } from 'lodash'

const TEAMS_QUERY = graphql`
  query TeamQuery {
    strapiGQL {
      teamMemberships(publicationState: LIVE) {
        leadership_teams(sort: "leadershipTeamOrder") {
          ...StrapiGQL_TeamFragment
        }
      }
    }
  }
`
export type ZoneTeamProps = {
  __typename: 'StrapiGQL_ComponentZonesTeam'
}
export default function ZoneTeam(props: ZoneTeamProps) {
  return (
    <StaticQuery
      query={TEAMS_QUERY}
      render={({ strapiGQL: { teamMemberships } }) => {
        const team_members = get(head(teamMemberships), 'leadership_teams')

        return (
          <Whitespace type="md">
            <Container>
              <Row>
                {mapIndexed(
                  (node: TeamMemberProps, index) => (
                    <Column size={6} sm={4} lg={4} key={`team-column-${index}`}>
                      <div style={{ maxWidth: 270 }}>
                        <TeamMember hasDialog={true} {...node} />
                      </div>
                    </Column>
                  ),
                  team_members || []
                )}
              </Row>
            </Container>
          </Whitespace>
        )
      }}
    />
  )
}
