import { resolveAsAttachment } from '@algbra/ui/resolvers'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import { graphql, StaticQuery } from 'gatsby'
import { map } from 'rambdax'
import styles from './Companies.module.scss'

export type InvestorCompany = {
  name: string
  logo: GatsbyResolvedAsset
}

const QUERY_INVESTOR_COMPANIES = graphql`
  query TeamInvestors {
    strapiGQL {
      investors(sort: "id:asc") {
        name
        logo {
          id
          url
          ext
          resolved {
            publicURL
          }
        }
      }
    }
  }
`

export default function Companies(): JSX.Element {
  return (
    <StaticQuery
      query={QUERY_INVESTOR_COMPANIES}
      render={({ strapiGQL: { investors } }) => (
        <div className={styles.list}>
          {map(
            ({ logo, name }: InvestorCompany) =>
              logo && (
                <div className={styles.item} key={`investor-company-${name}`}>
                  <img
                    src={resolveAsAttachment(logo)}
                    alt={`${name} Logo`}
                    loading="lazy"
                  />
                </div>
              ),
            investors
          )}
        </div>
      )}
    />
  )
}
