import Caption from '../../components/Caption/Caption'
import { Column, Container, Row } from '../../components/Grid/Grid'
import Heading from '../../components/Heading/Heading'
import Whitespace from '../../components/Whitespace/Whitespace'
import { VAlign } from '../../enums/align'
import { GatsbyResolvedAsset } from '../../types/image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { mapIndexed } from 'rambdax'
import React from 'react'
import { resolveAsImage } from '../../resolvers'
import styles from './IconBoxList.module.scss'
import classNames from 'classnames'

export type IconBox = {
  title: string
  description: string
  image?: GatsbyResolvedAsset
}
export type ZoneIconBoxListProps = {
  __typename: 'StrapiGQL_ComponentZonesIconBoxList'
  header: string
  containerBackground: string
  itemBackground: string
  titleFontSize: string
  descriptionFontSize: string
  descriptionColor: 'primary' | 'secondary'
  iconList: IconBox[]
  whitespace: 'xl' | 'lg' | 'md' | 'sm' | 'none'
}
export default function ZoneIconBoxList(props: ZoneIconBoxListProps) {
  const {
    iconList,
    header,
    containerBackground,
    itemBackground,
    titleFontSize,
    descriptionFontSize,
    descriptionColor,
    whitespace = 'lg',
  } = props
  return (
    <Whitespace type={whitespace}>
      <div
        className={styles.wrapper}
        style={{
          backgroundColor: containerBackground,
        }}
      >
        <Container>
          {header && (
            <div
              className={styles.containerHeader}
              dangerouslySetInnerHTML={{ __html: header }}
            ></div>
          )}

          <Row className={styles.list} vAlign={VAlign.STRETCH}>
            {mapIndexed(
              ({ title, image, description }, index) => (
                <Column
                  size={12}
                  sm={12}
                  lg={6}
                  key={`icon-item-${index}`}
                  className={styles.itemWrapper}
                  style={{ padding: !itemBackground ? 0 : undefined }}
                >
                  <div
                    className={classNames(styles.item, {
                      [styles.withoutPadding]: !itemBackground,
                    })}
                    style={{
                      backgroundColor: itemBackground,
                    }}
                  >
                    <div className={styles.header}>
                      <GatsbyImage
                        image={resolveAsImage(image)}
                        alt=""
                        className={styles.image}
                      />

                      {title && (
                        <Caption
                          type="lg"
                          className={styles.title}
                          style={{ fontSize: titleFontSize }}
                        >
                          {title}
                        </Caption>
                      )}
                    </div>

                    <div
                      className={classNames(styles.description, {
                        [styles[descriptionColor]]: descriptionColor,
                      })}
                      style={{
                        fontSize: descriptionFontSize,
                      }}
                    >
                      {description}
                    </div>
                  </div>
                </Column>
              ),
              iconList
            )}
          </Row>
        </Container>
      </div>
    </Whitespace>
  )
}
