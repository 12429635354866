import { FormInputEventProps } from '../../types/events'
import {
  FormElementStyleProps,
  FormInputProps,
  OptionItem,
} from '../../types/forms'
import classNames from 'classnames'
import React from 'react'
import Label from '../Label/Label'
import ValidationError from '../ValidationError/ValidationError'
import styles from './Select.module.scss'

export type SelectProps = {
  items?: readonly OptionItem[]
} & FormInputProps &
  FormInputEventProps<HTMLSelectElement> &
  FormElementStyleProps

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref): JSX.Element => {
    const {
      label,
      items,
      size = 'normal',
      className,
      controlClassName,
      placeholder,
      error,
      onChange,
      ...restProps
    } = props
    const onFormChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange && onChange(e)
    }
    return (
      <div className={classNames(styles.container, [styles[size]], className)}>
        {label && <Label className={styles.label}>{label}</Label>}
        <div className={styles.controlWrapper}>
          {placeholder && !restProps.value && <span className={styles.placeholderSpan}>{placeholder}</span>}
          <select
            {...restProps}
            ref={ref}
            onChange={onFormChange}
            className={classNames(
              styles.control,
              { [styles.error]: !!error },
              controlClassName
            )}
          >
            {placeholder && <option></option>}
            {items?.map(({ value, label }) => (
              <option key={`select-${restProps.name}-${value}`} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        {error && <ValidationError message={error.message} />}
      </div>
    )
  }
)

Select.displayName = 'Select'

export default Select
