import React from 'react'
import { resolveAsAttachment } from '../../resolvers'
import { GatsbyResolvedAsset } from '../../types/image'
import { map } from 'rambdax'
import styles from './LogoList.module.scss'
import Permalink from '../../components/Permalink/Permalink'
import { Column, Container, Row } from '../../components/Grid/Grid'

export type Logo = {
  id: string
  image: GatsbyResolvedAsset
  title: string
  link?: string
}
export type ZoneLogoListProps = {
  __typename: 'StrapiGQL_ComponentZonesLogoList'
  title: string
  items: Logo[]
}
export function ZoneLogoList({ title, items }: ZoneLogoListProps) {
  return (
    <Container className={styles.container}>
      <div className={styles.title}>{title}</div>

      <Row className={styles.row}>
        {map(
          ({ image, title, link }) =>
            link ? (
              <Column
                className={styles.column}
                size={6}
                sm={4}
                lg={2}
                key={`team-column-${image.id}`}
              >
                <Permalink
                  className={styles.item}
                  key={image.id}
                  to={link}
                  isBlank={true}
                  isExternal={true}
                >
                  <img
                    src={resolveAsAttachment(image)}
                    alt={`Logo: ${title}`}
                    loading="lazy"
                    width="100%"
                  />
                </Permalink>
              </Column>
            ) : (
              <Column
                className={styles.column}
                size={6}
                sm={4}
                lg={2}
                key={`team-column-${image.id}`}
              >
                <span className={styles.item} key={image.id}>
                  <img
                    src={resolveAsAttachment(image)}
                    alt={`Logo: ${title}`}
                    loading="lazy"
                    width="100%"
                  />
                </span>
              </Column>
            ),
          items || []
        )}
      </Row>
    </Container>
  )
}
