import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { ReactNode } from 'react'
import Advisors from './Advisors'
import Companies from './Companies'
import styles from './Investors.module.scss'

export type ZoneInvestorsProps = {
  __typename: 'StrapiGQL_ComponentZonesInvestorsAndAdvisors'
  title?: ReactNode
}
export default function ZoneInvestors({ title }: ZoneInvestorsProps) {
  return (
    <Container fluid={true}>
      <section className={styles.investors} id="investors">
        <Whitespace type="md">
          <div className={styles.advisors}>
            {title && (
              <Heading level={2} type="lg" className={styles.title}>
                {title}
              </Heading>
            )}

            <Advisors />
          </div>

          <div className={styles.companies}>
            <Heading level={2} type="lg" className={styles.title}>
              Corporate Partners
            </Heading>

            <Container>
              <Companies />
            </Container>
          </div>
        </Whitespace>
      </section>
    </Container>
  )
}
