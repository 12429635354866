import { WindowLocation } from '@reach/router'
import { mapIndexed } from 'rambdax'
import ZoneSplitBox, {
  ZoneSplitBoxProps,
} from '@algbra/ui/components/SplitBox/SplitBox'
import ZoneInlineWaitlist, {
  ZoneInlineWaitlistProps,
} from './InlineWaitlist/InlineWaitlist'
import ZoneIconBox, {
  ZoneIconBoxListProps,
} from '@algbra/ui/zones/IconBoxList/IconBoxList'
import ZoneTeam, { ZoneTeamProps } from './Team/Team'
import ZoneEthicsCommittee, {
  ZoneEthicsCommitteeProps,
} from './EthicsCommittee/EthicsCommittee'
import ZoneNewsList, { ZoneNewsListProps } from './News/NewsList'
import ZoneAnnualReports, {
  ZoneAnnualReportsProps,
} from './AnnualReports/AnnualReports'
import ZoneInvestors, { ZoneInvestorsProps } from './Investors/Investors'
import {
  ZoneLogoList,
  ZoneLogoListProps,
} from '@algbra/ui/zones/LogoList/LogoList'

export type ZoneItemType =
  | ZoneSplitBoxProps
  | ZoneInlineWaitlistProps
  | ZoneIconBoxListProps
  | ZoneTeamProps
  | ZoneEthicsCommitteeProps
  | ZoneNewsListProps
  | ZoneAnnualReportsProps
  | ZoneInvestorsProps
  | ZoneLogoListProps

export type ZoneRendererProps = {
  zones: ZoneItemType[]
  location: WindowLocation<{ value: string }>
}

export function getComponentByZoneType(
  zone: ZoneItemType,
  key: string,
  location?: WindowLocation<{ value: string }>
) {
  switch (zone.__typename) {
    case 'StrapiGQL_ComponentZonesSplitBox':
      return <ZoneSplitBox key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesInlineWaitlist':
      return <ZoneInlineWaitlist key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesIconBoxList':
      return <ZoneIconBox key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesTeam':
      return <ZoneTeam key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesEthicsCommittee':
      return <ZoneEthicsCommittee key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesNews':
      return <ZoneNewsList key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesAnnualReports':
      return <ZoneAnnualReports key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesInvestorsAndAdvisors':
      return <ZoneInvestors key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesLogoList':
      return <ZoneLogoList key={key} {...zone} />
    default:
      return null
  }
}
export default function MainZoneRenderer({
  zones,
  location,
}: ZoneRendererProps) {
  return (
    <main>
      {mapIndexed(
        (zone, index) =>
          getComponentByZoneType(zone, `${zone.__typename}-${index}`, location),
        zones
      )}
    </main>
  )
}
