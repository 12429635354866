import React from 'react'
import { Column, Row } from '@algbra/ui/components/Grid/Grid'
import TeamMember, {
  TeamMemberProps,
} from '@algbra/ui/components/TeamMember/TeamMember'
import { graphql, StaticQuery } from 'gatsby'
import { map } from 'rambdax'

const QUERY_ADVISORS = graphql`
  query AdvisorsQuery {
    strapiGQL {
      advisors(sort: "order") {
        id
        firstName
        lastName
        bio
        picture {
          id
          url
          ext
          resolved {
            childImageSharp {
              gatsbyImageData(
                width: 90
                layout: FIXED
                formats: WEBP
                webpOptions: { quality: 100 }
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`
export default function Advisors() {
  return (
    <StaticQuery
      query={QUERY_ADVISORS}
      render={({ strapiGQL: { advisors } }) => {
        return (
          <Row>
            {map(
              (node: TeamMemberProps) => (
                <Column size={12} sm={6} lg={3} key={node.id}>
                  <TeamMember
                    {...node}
                    direction="horizontal"
                    hasDialog={true}
                  />
                </Column>
              ),
              advisors
            )}
          </Row>
        )
      }}
    />
  )
}
