import Button from '../Button/Button'
import FreeText from '../FreeText/FreeText'
import { Column, Container, Row } from '../Grid/Grid'
import Heading from '../Heading/Heading'
import { VAlign } from '../../enums/align'
import { getIconByName, IconNameType } from '../../icons'
import { GatsbyResolvedAsset } from '../../types/image'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'
import { resolveAsImage } from '../../resolvers'
import styles from './SplitBox.module.scss'

export type ZoneSplitBoxProps = {
  __typename: 'StrapiGQL_ComponentZonesSplitBox'
  title: string
  description: ReactNode
  image?: GatsbyResolvedAsset
  color?: string
  buttonText?: string
  buttonLink?: string
  buttonTheme?: 'primary' | 'light' | 'tetriary'
  buttonTarget: '_self' | 'new'
  iconName: IconNameType
}
export default function ZoneSplitBox(props: ZoneSplitBoxProps) {
  const { color = 'gray', title, description, image, iconName, buttonTarget } = props
  const itemImage = image && resolveAsImage(image)
  const textColor = color !== 'dark' ? 'primary' : 'white'
  return (
    <Container className={styles.container}>
      <section
        className={classNames(styles.splitBox, styles[`color__${color}`])}
      >
        <Row
          className={styles.stretchRow}
          vAlign={VAlign.STRETCH}
          gutter={false}
        >
          <Column className={classNames(styles.padded)}>
            <Heading type="lg" level={2} className={styles.title}>
              {title}
            </Heading>
            <div className={styles.children}>
              <FreeText type="md" color={textColor} innerHTML={description}>
                {description}
              </FreeText>
            </div>

            {props.buttonText && (
              <Button
                className={styles.button}
                icon={getIconByName(iconName)}
                onClick={() => window.open(props.buttonLink, buttonTarget)}
                theme={props.buttonTheme}
                size="small"
              >
                {props.buttonText}
              </Button>
            )}
          </Column>
          <Column auto={true} className={styles.image}>
            {itemImage && (
              <GatsbyImage
                loading="lazy"
                image={itemImage}
                alt=""
                role="presentation"
              />
            )}
          </Column>
        </Row>
      </section>
    </Container>
  )
}
